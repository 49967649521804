import React from 'react';
import styled from 'styled-components';
import { Field as _Field } from 'formik';
import get from 'lodash/get';

import { COLORS, MEDIA_QUERY } from '../../../../commonStyles';

export const InputContainer = styled.div({
  paddingTop: '42px',
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingTop: '32px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

export const InputLabel = styled.label({
  display: 'block',
  fontSize: '16px',
  lineHeight: '24px',
  paddingBottom: '6px',
  cursor: 'pointer',
  color: COLORS.DARK_GREY,
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
    lineHeight: '15px',
    paddingBottom: '12px',
  },
});

export const InputLabelAsterisk = styled.span({
  color: COLORS.RED,
});

export const InputRequired = () => <InputLabelAsterisk>*</InputLabelAsterisk>;

const inputStyles = {
  width: '100%',
  padding: '9px 11px',
  fontSize: '18px',
  lineHeight: '28px',
  backgroundColor: COLORS.GRAY,
  outline: 'none',
  border: `1px solid ${COLORS.GRAY}`,
  height: '48px',
  boxSizing: 'border-box',
  borderRadius: 0,
  appearance: 'none',
  ':focus': {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.BLACK,
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '25px',
    height: '45px',
  },
};

const Input = styled.input({ ...inputStyles }, ({ hasError }) => {
  return hasError
    ? {
        color: 'red',
        border: '1px solid red',
        ':focus': {
          borderColor: 'red',
        },
      }
    : {};
});

type FieldProps = {
  maxLength?: number;
  name: string;
  type: string;
  id?: string;
  placeholder?: string;
};

export const InputField: React.FC<FieldProps> = props => {
  return (
    <_Field {...props}>
      {({ field, form }) => {
        const hasError = Boolean(get(form.errors, props.name));
        const isTouched = Boolean(get(form.touched, props.name));
        return (
          <Input
            hasError={hasError && isTouched}
            {...props}
            {...field}
            onChange={e => {
              field.onChange(e);
            }}
          />
        );
      }}
    </_Field>
  );
};

export const InputSelectField = styled(_Field)({
  ...inputStyles,
  borderRadius: 0,
  appearance: 'none',
});

export const InputRecurlyField = styled.div({
  '.recurly-hosted-field': {
    ...inputStyles,
  },
  '.recurly-hosted-field-focus': {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.BLACK,
  },
  '.recurly-hosted-field-invalid': {
    borderColor: COLORS.RED,
  },
});
