import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { productsStore } from '../../storage/global-state';

const productVariantsQuery = (language: string) => {
  const { allContentfulProductVariant } = useStaticQuery(graphql`
    query ProductsQuery {
      allContentfulProductVariant {
        nodes {
          node_locale
          id
          name
          skuCode
          price
          product {
            title
            slug
            basketImage {
              file {
                url
              }
            }
          }
          dishCollection {
            amount
            dish {
              type
              slug
            }
          }
        }
      }
    }
  `);
  return allContentfulProductVariant.nodes.filter(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};
const withShoppingCart = (Component, language) => props => {
  const productVariants = productVariantsQuery(language);
  const [state] = productsStore();
  const products = state.products.map(({ skuCode }) =>
    productVariants.find((pv: any) => pv.skuCode === skuCode)
  );

  return <Component basket={products} language={language} {...props} />;
};

export default withShoppingCart;
