import axios from 'axios';

const baseURL = process.env.GATSBY_API_URL;

const API = axios.create({
  baseURL,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

export const sendOrder = async (data: any) => API.post(`/checkout`, data);
