import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Field as _Field } from 'formik';
import { COLORS, MEDIA_QUERY } from '../../../../commonStyles';

export const CheckboxContainer = styled.div({
  marginTop: '42px',
  display: 'flex',
  flexWrap: 'nowrap',
  position: 'relative',
  alignItems: 'center',
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    alignItems: 'flex-start',
    marginTop: '32px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

export const CheckboxLabel = styled.label({
  marginLeft: '16px',
  fontSize: '18px',
  lineHeight: '28px',
  cursor: 'pointer',
  color: COLORS.DARK_GREY,
  a: {
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const CheckboxBox = styled.div(
  {
    position: 'relative',
    width: '38px',
    height: '38px',
    boxSizing: 'border-box',
    border: `2px solid ${COLORS.BLACK}`,
    cursor: 'pointer',
    input: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
      border: 0,
      opacity: 0,
      cursor: 'pointer',
    },
    [MEDIA_QUERY.MOBILE]: {
      marginTop: '2px',
      width: '20px',
      height: '20px',
    },
  },
  ({ hasError }) => hasError && { borderColor: 'red' }
);

const CheckboxCheckmark = styled.div(
  {
    position: 'relative',
    cursor: 'pointer',
    top: 0,
    left: 0,
    height: '34px',
    width: '34px',
    ':after': {
      content: '""',
      position: 'absolute',
      transition: 'opacity 0.2s ease-in-out',
      bottom: '5px',
      left: '10px',
      width: '12px',
      height: '24px',
      border: `solid ${COLORS.BLACK}`,
      borderWidth: '0 3px 3px 0',
      '-webkit-transform': 'rotate(45deg)',
      '-ms-transform': 'rotate(45deg)',
      transform: 'rotate(45deg)',
    },
    [MEDIA_QUERY.MOBILE]: {
      width: '16px',
      height: '16px',
      ':after': {
        borderWidth: '0 2px 2px 0',
        bottom: '3px',
        left: '4px',
        width: '6px',
        height: '12px',
      },
    },
  },
  ({ checked }: { checked: boolean }) =>
    checked ? { ':after': { opacity: 1 } } : { ':after': { opacity: 0 } }
);

type CheckboxProps = {
  name?: string;
  id?: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, id }) => {
  return (
    <_Field name={name} type="checkbox">
      {({ field, form }) => {
        const hasError = Boolean(get(form.errors, name));
        const isTouched = Boolean(get(form.touched, name));
        return (
          <CheckboxBox hasError={hasError && isTouched}>
            <CheckboxCheckmark checked={form.values[name]} />
            <input
              {...field}
              id={id}
              type="checkbox"
              name={name}
              onChange={e => {
                field.onChange(e);
              }}
            />
          </CheckboxBox>
        );
      }}
    </_Field>
  );
};
