import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../../../commonStyles';

export const FormParagraph = styled.div({
  fontSize: '18px',
  lineHeight: '28px',
  paddingTop: '54px',
  color: COLORS.DARK_GREY,
  a: {
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    }
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: '32px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});