import React, { useState } from 'react';
import styled from 'styled-components';
import { getTotalPrice } from '../../../storage/session-storage';
import { i18n } from '../../../translations';
import * as Icon from '../../../assets/icons';
import { COLORS, MEDIA_QUERY } from '../../../commonStyles';
import { formatPrice } from '../../../functions/utils';
import { Orders } from './orders-list';

type Props = {
  language: string;
  basket: [object];
};

const Container = styled.div({
  alignSelf: 'flex-start',
  position: 'sticky',
  top: '126px',
  right: '0',
  width: '480px',
  padding: '0 24px',
  background: COLORS.LIGHT_GREY,
  boxSizing: 'border-box',
  [MEDIA_QUERY.TABLET]: {
    position: 'fixed',
    width: 'auto',
    left: 0,
    top: '84px',
    boxShadow: '0 4px 8px -4px rgba(0,0,0,0.2)',
    zIndex: 98,
    background: COLORS.WHITE,
    padding: '0 42px',
  },
  [MEDIA_QUERY.MOBILE]: {
    position: 'fixed',
    width: 'auto',
    left: 0,
    top: '73px',
    boxShadow: '0 4px 8px -4px rgba(0,0,0,0.2)',
    zIndex: 98,
    background: COLORS.WHITE,
    padding: '0 15px',
  },
});

const Summary = styled.div({
  padding: '28px 0 32px',
  [MEDIA_QUERY.TABLET]: {
    padding: '15px 0 16px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '13px 0 13px',
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
});

const SummaryTop = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '24px',
  fontSize: '18px',
  position: 'relative',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '16px',
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
});

const SummaryTitle = styled.div({
  display: 'flex',
});

const SummaryTitleIcon = styled.div({
  height: '24px',
  width: '24px',
  img: {
    width: '100%',
    verticalAlign: 'middle',
  },
  [MEDIA_QUERY.MOBILE]: {
    height: '18px',
    width: '18px',
  },
});

const SummaryTitleText = styled.span({
  padding: '0 0 0 8px',
  display: 'block',
});

const SummaryPrice = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  [MEDIA_QUERY.TABLET]: {
    marginRight: '56px',
  },
  [MEDIA_QUERY.MOBILE]: {
    marginRight: '30px',
  },
});

const SummaryPriceLabel = styled.span({
  padding: '0 12px 0 0',
  display: 'block',
  [MEDIA_QUERY.TABLET]: {
    padding: '0 40px 0 0',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '0 10px 0 0',
  },
});

const SummaryPriceValue = styled.div({
  fontWeight: 'bold',
  textAlign: 'right',
});

type ButtonProps = {
  active: boolean;
};

const SummaryToggle = styled.button(
  {
    display: 'none',
    width: '24px',
    height: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    [MEDIA_QUERY.TABLET]: {
      display: 'block',
      position: 'absolute',
      right: 0,
      top: '16px',
    },
    [MEDIA_QUERY.MOBILE]: {
      display: 'block',
      position: 'absolute',
      right: 0,
      top: '13px',
      width: '15px',
      height: '15px',
    },
  },
  ({ active }: ButtonProps) =>
    active
      ? {
          transform: 'rotate(180deg)',
        }
      : {
          transform: 'rotate(0deg)',
        }
);

const SummaryYear = styled.div({
  paddingTop: '12px',
  fontSize: '18px',
  lineHeight: '20px',
  textAlign: 'right',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '16px',
    marginRight: '56px',
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
    lineHeight: '15px',
    marginRight: '30px',
    paddingTop: '9px',
  },
});

const Basket: React.FC<Props> = ({ language, basket }) => {
  const totalPrice = getTotalPrice();
  const yearlyPrice = totalPrice * 12;

  const __ = i18n(language);
  const [ordersVisible, setOrdersVisible] = useState(false);

  return (
    <Container>
      <Summary>
        <SummaryTop>
          <SummaryTitle>
            <SummaryTitleIcon>
              <Icon.ShoppingCartCheckout />
            </SummaryTitleIcon>
            <SummaryTitleText>{__('shoppingCart.yourOrder')}</SummaryTitleText>
          </SummaryTitle>
          <SummaryPrice>
            <SummaryPriceLabel>{__('shoppingCart.inTotal')} </SummaryPriceLabel>
            <SummaryPriceValue>
              {formatPrice(totalPrice / 100)}
              {__('shoppingCart.eurPerMonth')}
            </SummaryPriceValue>
            <SummaryToggle
              onClick={() => setOrdersVisible(!ordersVisible)}
              active={ordersVisible}
            >
              <Icon.Arrow />
            </SummaryToggle>
          </SummaryPrice>
        </SummaryTop>
        <SummaryYear>
          ({formatPrice(yearlyPrice / 100)} {__('shoppingCart.eurPerYear')})
        </SummaryYear>
      </Summary>
      <Orders items={basket} i18n={__} visible={ordersVisible} />
    </Container>
  );
};

export default Basket;
