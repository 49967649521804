import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../../commonStyles';

import { prefixes } from './data/contact-prefixes';
import {
  InputContainer,
  InputLabel,
  InputRequired,
  InputField,
  InputSelectField,
  PhoneGroup,
} from './ui';

type Props = {
  visible: boolean;
  __: (key: string) => string;
};

interface ContainerProps {
  visible: boolean;
}

const Container = styled.div<ContainerProps>(props => ({
  overflow: 'hidden',
  transition: 'max-height 0.4s ease-in-out',
  maxHeight: props.visible ? '1000px' : '0px',
}));

const Title = styled.div({
  paddingTop: '42px',
  marginBottom: '-21px',
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight: 'bold',
  color: COLORS.BLACK,
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingTop: '32px',
    marginBottom: '-16px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: '16px',
  },
});

export const AdditionalAddress: React.FC<Props> = ({ visible, __ }) => (
  <Container visible={visible}>
    <Title>Toimitusosoite</Title>
    <InputContainer>
      <InputLabel htmlFor="customer-form-additional-first-name">
        {__('userDataForm.firstName')} <InputRequired />
      </InputLabel>
      <InputField
        type="text"
        name="additionalAddress.firstName"
        id="customer-form-additional-first-name"
      />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-additional-last-name">
        {__('userDataForm.lastName')} <InputRequired />
      </InputLabel>
      <InputField
        type="text"
        name="additionalAddress.lastName"
        id="customer-form-additional-last-name"
      />
    </InputContainer>
    <PhoneGroup>
      <InputContainer>
        <InputLabel htmlFor="customer-form-additional-phone-prefix">
          {__('userDataForm.phonePrefix')} <InputRequired />
        </InputLabel>
        <InputSelectField
          component="select"
          name="additionalAddress.phonePrefix"
          id="customer-form-additional-phone-prefix"
        >
          {prefixes.map((p, index) => (
            <option value={p} key={`p-${index}`}>
              +{p}
            </option>
          ))}
        </InputSelectField>
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="customer-form-additional-phone">
          {__('userDataForm.phoneNumber')} <InputRequired />
        </InputLabel>
        <InputField
          type="tel"
          name="additionalAddress.phone"
          id="customer-form-additional-phone"
        />
      </InputContainer>
    </PhoneGroup>
    <InputContainer>
      <InputLabel htmlFor="customer-form-additional-street">
        {__('userDataForm.streetAddress')} <InputRequired />
      </InputLabel>
      <InputField
        type="text"
        name="additionalAddress.street[0]"
        id="customer-form-additional-street"
      />
    </InputContainer>
    <InputContainer>
      <InputField type="text" name="additionalAddress.street[1]" />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-additional-zip">
        {__('userDataForm.zipCode')} <InputRequired />
      </InputLabel>
      <InputField
        type="tel"
        pattern="[0-9]*"
        spellcheck="false"
        autocapitalize="none"
        autocorrect="off"
        maxLength={5}
        name="additionalAddress.zip"
        id="customer-form-additional-zip"
      />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-additional-city">
        {__('userDataForm.city')} <InputRequired />
      </InputLabel>
      <InputField
        type="text"
        name="additionalAddress.city"
        id="customer-form-additional-city"
      />
    </InputContainer>
  </Container>
);
