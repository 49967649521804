import { get } from 'lodash';

interface TrackedProduct {
  name: string; //Human readable product name
  variant: string; //How many pieces
  id: string; //Product ID
  price: string; //Monthly price * 12 months, dot as a separator
  brand: string;
  category: string;
  quantity: 1; //Value is always 1
}

interface PurchasesTrackingAction {
  id: string; // order id
  affiliation: 'Arabia Astiapalvelu';
  revenue: string; //Total revenue EXCLUDING VAT
  tax: string; //VAT amount
  shipping: string; //Shipping costs (if any)
}

interface PurchasesTrackingEvent {
  event: 'ecommerce';
  eventDescription: 'transaction confirmation';
  ecommerce: {
    currencyCode: 'EUR';
    purchase: {
      actionField: PurchasesTrackingAction;
      products: [TrackedProduct];
    };
  };
}

const toTrackedProduct = (productVariant: object): TrackedProduct => {
  return {
    name: get(productVariant, 'product[0].title'),
    variant: get(productVariant, 'name'),
    id: get(productVariant, 'product[0].id'),
    price: `${(get(productVariant, 'price') / 100) * 12}`,
    brand: get(productVariant, 'product[0].brand.title', ''),
    category: get(productVariant, 'product[0].category.name', ''),
    quantity: 1,
  };
};

const totalSum = (products: [object]): number =>
  products.map(p => (p.price / 100) * 12).reduce((a, b) => a + b);

const createPurchaseTrackingAction = (
  purchaseId: string,
  products: [object]
): PurchasesTrackingAction => ({
  id: purchaseId,
  affiliation: 'Arabia Astiapalvelu',
  revenue: `${totalSum(products) * 0.76}`,
  tax: `${totalSum(products) * 0.24}`,
  shipping: '0.0',
});

const createPurchaseTrackingEvent = (
  purchaseId: string,
  products: [object]
): PurchasesTrackingEvent => ({
  event: 'ecommerce',
  eventDescription: 'transaction confirmation',
  ecommerce: {
    currencyCode: 'EUR',
    purchase: {
      actionField: createPurchaseTrackingAction(purchaseId, products),
      products: products.map(toTrackedProduct),
    },
  },
});

export const trackPurchasedProducts = (
  purchaseId = 'TODO',
  products: [object]
) => {
  const trackingEvent: PurchasesTrackingEvent = createPurchaseTrackingEvent(
    purchaseId,
    products
  );
  window.dataLayer && window.dataLayer.push(trackingEvent);
};
