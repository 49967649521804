import { UserData } from './schema';
export const emptyAddress = {
  street: ['', ''],
  zip: '',
  city: '',
};

export const emptyAdditionalAddress = {
  firstName: '',
  lastName: '',
  phonePrefix: '358',
  phone: '',
  street: ['', ''],
  zip: '',
  city: '',
};

export const initialValues: UserData = {
  firstName: '',
  lastName: '',
  birthDay: '',
  birthMonth: '',
  birthYear: '',
  email: '',
  phonePrefix: '358',
  phone: '',
  hasAdditionalAddress: false,
  address: emptyAddress,
  additionalAddress: emptyAdditionalAddress,
  card: {
    firstName: '',
    lastName: '',
    number: '',
    expirationYear: '',
    expirationMonth: '',
    cvv: '',
  },
  subscribeToNewsletter: false,
  acceptTerms: false,
};
