import React from 'react';

import { prefixes } from './data/contact-prefixes';
import {
  StepTitle,
  Step,
  InputContainer,
  InputLabel,
  InputRequired,
  InputField,
  InputSelectField,
  FormParagraph,
  CheckboxContainer,
  CheckboxLabel,
  Checkbox,
  PhoneGroup,
  BirthdayGroup,
} from './ui';

type Props = {
  __: (key: string) => string;
};

export const BasicInformation: React.FC<Props> = ({ __ }) => (
  <Step>
    <StepTitle>{__('userDataForm.customerInformation')}</StepTitle>
    <InputContainer>
      <InputLabel htmlFor="customer-form-first-name">
        {__('userDataForm.firstName')} <InputRequired />
      </InputLabel>
      <InputField type="text" name="firstName" id="customer-form-first-name" />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-last-name">
        {__('userDataForm.lastName')} <InputRequired />
      </InputLabel>
      <InputField type="text" name="lastName" id="customer-form-last-name" />
    </InputContainer>
    <BirthdayGroup>
      <InputContainer>
        <InputLabel htmlFor="customer-form-birth-day">
          {__('userDataForm.birthday')} <InputRequired />
        </InputLabel>
        <InputField
          type="tel"
          pattern="[0-9]*"
          spellcheck="false"
          autocapitalize="none"
          autocorrect="off"
          name="birthDay"
          id="customer-form-birth-day"
          maxLength={2}
          placeholder={__('userDataForm.placeholder.birth.day')}
        />
      </InputContainer>
      <InputContainer>
        <InputField
          type="tel"
          pattern="[0-9]*"
          spellcheck="false"
          autocapitalize="none"
          autocorrect="off"
          name="birthMonth"
          maxLength={2}
          placeholder={__('userDataForm.placeholder.birth.month')}
        />
      </InputContainer>
      <InputContainer>
        <InputField
          type="tel"
          pattern="[0-9]*"
          spellcheck="false"
          autocapitalize="none"
          autocorrect="off"
          name="birthYear"
          maxLength={4}
          placeholder={__('userDataForm.placeholder.birth.year')}
        />
      </InputContainer>
    </BirthdayGroup>
    <InputContainer>
      <InputLabel htmlFor="customer-form-email">
        {__('userDataForm.email')} <InputRequired />
      </InputLabel>
      <InputField type="email" name="email" id="customer-form-email" />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-confirm-email">
        {__('userDataForm.confirmEmail')} <InputRequired />
      </InputLabel>
      <InputField
        type="email"
        name="confirmEmail"
        id="customer-form-confirm-email"
      />
    </InputContainer>
    <PhoneGroup>
      <InputContainer>
        <InputLabel htmlFor="customer-form-phone-prefix">
          {__('userDataForm.phonePrefix')} <InputRequired />
        </InputLabel>
        <InputSelectField
          component="select"
          name="phonePrefix"
          id="customer-form-phone-prefix"
        >
          {prefixes.map((p, index) => (
            <option value={p} key={`p-${index}`}>
              +{p}
            </option>
          ))}
        </InputSelectField>
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="customer-form-phone">
          {__('userDataForm.phoneNumber')} <InputRequired />
        </InputLabel>
        <InputField type="tel" name="phone" id="customer-form-phone" />
      </InputContainer>
    </PhoneGroup>
    <InputContainer>
      <InputLabel htmlFor="customer-form-address">
        {__('userDataForm.streetAddress')} <InputRequired />
      </InputLabel>
      <InputField
        type="text"
        name="address.street[0]"
        id="customer-form-address"
      />
    </InputContainer>
    <InputContainer>
      <InputField type="text" name="address.street[1]" />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-zip">
        {__('userDataForm.zipCode')} <InputRequired />
      </InputLabel>
      <InputField
        type="tel"
        pattern="[0-9]*"
        spellcheck="false"
        autocapitalize="none"
        autocorrect="off"
        maxLength={5}
        name="address.zip"
        id="customer-form-zip"
      />
    </InputContainer>
    <InputContainer>
      <InputLabel htmlFor="customer-form-city">
        {__('userDataForm.city')} <InputRequired />
      </InputLabel>
      <InputField type="text" name="address.city" id="customer-form-city" />
    </InputContainer>
    <FormParagraph>{__('userDataForm.additionalAddress')}</FormParagraph>
    <CheckboxContainer>
      <Checkbox
        name="hasAdditionalAddress"
        id="customer-form-additional-addreess"
      />
      <CheckboxLabel htmlFor="customer-form-additional-addreess">
        {__('userDataForm.additionalAddressCheckbox')}
      </CheckboxLabel>
    </CheckboxContainer>
  </Step>
);
