import React from 'react';
import styled from 'styled-components';
import * as Icon from '../../assets/icons';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import { Link } from 'gatsby';
import { i18n } from '../../translations';
import { getLink } from '../../constants/links';

const GoBackContainer = styled.div`
  display: flex;
  flex: 1 33%;
`;

const Back = styled(Link)({
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  textDecoration: 'none',
  color: COLORS.BLACK,
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  flexDirection: 'column',
  opacity: 1,
  '&:hover': {
    opacity: 0.4,
  },
});

export interface GoBackProps {
  language: string;
  className?: string;
}

const Label = styled.span({
  textAlign: 'left',
  marginTop: '12px',
  fontSize: '16px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
  },
});

export const GoBackRenderer: React.FC<GoBackProps> = ({
  language,
  className,
}) => {
  const __ = i18n(language);
  const goBackUrl = getLink(language, 'productPicker');

  return (
    <GoBackContainer>
      <Back to={goBackUrl} className={className}>
        <Icon.LongArrow />
        <Label>{__('header.continueShopping')}</Label>
      </Back>
    </GoBackContainer>
  );
};
