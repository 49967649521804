import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../../commonStyles';

export const PhoneGroup = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  '> div': {
    ':first-of-type': {
      width: '154px',
    },
    ':last-of-type': {
      width: '317px',
    },
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
    '> div': {
      ':first-of-type': {
        width: '144px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      ':last-of-type': {
        width: '296px',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    '> div': {
      ':first-of-type': {
        width: '32%',
        paddingLeft: '0',
        paddingRight: '0',
      },
      ':last-of-type': {
        width: '66%',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
});

export const BirthdayGroup = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  '> div': {
    width: '154px',
    alignSelf: 'flex-end',
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
    '> div': {
      width: '144px',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    '> div': {
      width: '32%',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
});
