import React from 'react';
import styled from 'styled-components';

import { MEDIA_QUERY } from '../../../commonStyles';
import {
  StepTitle,
  Step,
  InputContainer,
  InputLabel,
  InputRequired,
  InputField,
  InputRecurlyField,
} from './ui';
import * as Icon from '../../../assets/icons';

type Props = {
  __: (key: string) => string;
};

const HalfGroup = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  '> div': {
    ':first-of-type': {
      width: '224px',
    },
    ':last-of-type': {
      marginTop: 'auto',
      width: '224px',
    },
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
    '> div': {
      ':first-of-type': {
        width: '208px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      ':last-of-type': {
        width: '208px',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    '> div': {
      ':first-of-type': {
        width: '46%',
        paddingLeft: '0',
        paddingRight: '0',
      },
      ':last-of-type': {
        width: '46%',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
});

const CvvIcon = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
});

export const Payment: React.FC<Props> = ({ __ }) => {
  return (
    <Step withMarginTop>
      <StepTitle>{__('userDataForm.payment')}</StepTitle>
      <input type="hidden" name="recurly-token" data-recurly="token" />
      <InputContainer>
        <InputLabel htmlFor="customer-form-payment-name">
          {__('userDataForm.cardholderName')} <InputRequired />
        </InputLabel>
        <InputField
          data-recurly="first_name"
          type="text"
          name="card.firstName"
          id="customer-form-payment-name"
        />
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="customer-form-payment-last">
          {__('userDataForm.cardholderLastName')} <InputRequired />
        </InputLabel>
        <InputField
          data-recurly="last_name"
          type="text"
          name="card.lastName"
          id="customer-form-payment-last"
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>
          {__('userDataForm.cardNumber')} <InputRequired />
        </InputLabel>
        <InputRecurlyField data-recurly="number" />
      </InputContainer>
      <HalfGroup>
        <InputContainer>
          <InputLabel>
            {__('userDataForm.expirationDate')} <InputRequired />
          </InputLabel>
          <InputRecurlyField data-recurly="month" id="recurly-month" />
        </InputContainer>
        <InputContainer>
          <InputRecurlyField data-recurly="year" />
        </InputContainer>
      </HalfGroup>
      <HalfGroup>
        <InputContainer>
          <InputLabel>
            {__('userDataForm.cvv')} <InputRequired />
          </InputLabel>
          <InputRecurlyField data-recurly="cvv" id="recurly-cvv" />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            {__('userDataForm.whatIsCvv')}
          </InputLabel>
          <CvvIcon>
            <Icon.Cvv />
          </CvvIcon>
        </InputContainer>
      </HalfGroup>
    </Step>
  );
};
