import React from 'react';
import {
  Step,
  FormParagraph,
  CheckboxContainer,
  CheckboxLabel,
  Checkbox,
} from './ui';
import styled from 'styled-components';
import { getLink } from '../../../constants/links';
import { COLORS, MEDIA_QUERY } from '../../../commonStyles';

type Props = {
  __: (key: string) => string;
  showError: boolean;
  hasItems: boolean;
  errorMessage?: string;
  language: string;
};

const Error = styled.div({
  marginTop: '42px',
  padding: '12px 70px',
  border: `1px solid ${COLORS.RED}`,
  lineHeight: '28px',
  fontSize: '18px',
  fontWeight: 'bold',
  color: COLORS.RED,
  backgroundColor: COLORS.OPAQUE_RED,
  textAlign: 'center',
  [MEDIA_QUERY.TABLET]: {
    marginLeft: '32px',
    marginRight: '32px',
    padding: '12px 54px',
  },
  [MEDIA_QUERY.MOBILE]: {
    marginTop: '32px',
    marginLeft: '15px',
    marginRight: '15px',
    lineHeight: '24px',
    fontSize: '15px',
    padding: '12px',
  },
});

const SubmitButtonContainer = styled.div({
  marginTop: '42px',
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    marginTop: '32px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

const SubmitButton = styled.button(
  {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    width: '100%',
    height: '48px',
    padding: '10px',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.4s ease, border-color 0.4s ease',
    '&:hover': {
      backgroundColor: COLORS.LIGHT_DARK_GREY,
      borderColor: COLORS.LIGHT_DARK_GREY,
    },
    [MEDIA_QUERY.MOBILE]: {
      height: '45px',
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  ({ disabled }: { disabled: boolean }) =>
    disabled
      ? { opacity: 0.3, cursor: 'not-allowed', '&:hover': { opacity: 0.3 } }
      : { opacity: 1, cursor: 'pointer' }
);

export const Submit: React.FC<Props> = ({
  __,
  showError,
  hasItems,
  errorMessage,
  language,
}) => {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <Step withSeparator>
      <CheckboxContainer>
        <Checkbox name="acceptTerms" id="customer-form-terms" />
        <CheckboxLabel
          htmlFor="customer-form-terms"
          dangerouslySetInnerHTML={{
            __html: __('userDataForm.submitCheckboxTerms')(
              getLink(language, 'termsOfUse')
            ),
          }}
        />
      </CheckboxContainer>
      <FormParagraph>
        {__('userDataForm.automaticAccountCreation')}
      </FormParagraph>
      <CheckboxContainer>
        <Checkbox name="subscribeToNewsletter" id="customer-form-newsletter" />
        <CheckboxLabel htmlFor="customer-form-newsletter">
          {__('userDataForm.submitCheckboxNewsletter')}
        </CheckboxLabel>
      </CheckboxContainer>
      <FormParagraph
        dangerouslySetInnerHTML={{
          __html: __('userDataForm.submitTextPrivacy')(
            getLink(language, 'privacyPolicy')
          ),
        }}
      />
      {showError && errorMessage && <Error>{errorMessage}</Error>}
      {!hasItems && <Error>{__('userDataForm.errorOrderSomething')}</Error>}
      <SubmitButtonContainer>
        <SubmitButton disabled={showError || !hasItems} type="submit">
          {__('userDataForm.submitOrderNow')}
        </SubmitButton>
      </SubmitButtonContainer>
    </Step>
  );
};
