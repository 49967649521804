import * as yup from 'yup'; // for everything

export type Address = {
  city: string;
  zip: string;
  street: string[];
};

export type AdditionalAddress = {
  firstName: string;
  lastName: string;
  phonePrefix: string;
  phone: string;
  city: string;
  zip: string;
  street: string[];
};

export type UserData = {
  firstName: string;
  lastName: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  email: string;
  phonePrefix: string;
  phone: string;
  address: Address;
  hasAdditionalAddress: boolean;
  additionalAddress?: AdditionalAddress;
  card: {
    firstName: string;
    lastName: string;
    number: string;
    expirationMonth: string;
    expirationYear: string;
    cvv: string;
  };
  acceptTerms: boolean;
  subscribeToNewsletter: boolean;
};

const addressSchema = yup.object().shape({
  street: yup
    .array()
    .compact()
    .of(yup.string().required())
    .min(1)
    .required(),
  zip: yup.string().required(),
  city: yup.string().required(),
});

const additionalAddressSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phonePrefix: yup.string().required(),
  phone: yup.string().required(),
  street: yup
    .array()
    .compact()
    .of(yup.string().required())
    .min(1)
    .required(),
  zip: yup.string().required(),
  city: yup.string().required(),
});

export const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthDay: yup.string().required(),
  birthMonth: yup.string().required(),
  birthYear: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  confirmEmail: yup
    .string()
    .email()
    .oneOf([yup.ref('email'), null])
    .required(),
  phonePrefix: yup.string().required(),
  phone: yup.string().required(),
  address: addressSchema.required(),
  hasAdditionalAddress: yup.boolean().required(),
  additionalAddress: yup.object().when('hasAdditionalAddress', {
    is: true,
    then: additionalAddressSchema.required(),
    otherwise: yup.object().notRequired(),
  }),
  acceptTerms: yup.boolean().oneOf([true]),
  subscribeToNewsletter: yup.boolean().default(false),
  card: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  }),
});
