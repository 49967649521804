import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../../../commonStyles';

export const StepTitle = styled.div({
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '28px',
  paddingBottom: '17px',
  color: COLORS.DARK_GREY,
  borderBottom: `1px solid ${COLORS.BLACK}`,
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '9px',
  },
});

export const Step = styled.div(
  ({ withMarginTop }) => (withMarginTop ? { marginTop: '84px' } : {}),
  ({ withSeparator }) =>
    withSeparator
      ? {
          marginTop: '42px',
          borderTop: '1px solid #000',
        }
      : {}
);
