import React from 'react';
import styled from 'styled-components';

import { GoBackRenderer } from '../../components/header/go-back-renderer';
import Basket from './basket/basket';
import Form from './form';
import { Layout } from '../../components';
import { MEDIA_QUERY } from '../../commonStyles';
import { i18n } from '../../translations';

type Props = {
  language: string;
  basket: [object];
};

const Container = styled.div({
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '72px',
  width: '992px',
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  [MEDIA_QUERY.TABLET]: {
    width: 'auto',
    display: 'block',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
    display: 'block',
    paddingBottom: '45px',
  },
});

const OrderSummary: React.FC<Props> = ({ basket, language }) => {
  const __ = i18n(language);

  return (
    <Layout
      language={language}
      title={__('userDataForm.checkout')}
      headerLeftRenderer={GoBackRenderer}
      headerHideCart
      hideFooter
      hideFooterBox
      headerHideHamburger
    >
      <Container>
        <Form language={language} />
        <Basket basket={basket} language={language} />
      </Container>
    </Layout>
  );
};

export default OrderSummary;
