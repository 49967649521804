import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  COLORS,
  EaseInOutTransition,
  MEDIA_QUERY,
} from '../../../commonStyles';
import { formatPrice } from '../../../functions/utils';
import { getTotalPrice } from '../../../storage/session-storage';

const PopupContainer = styled.div(
  {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: COLORS.LIGHT_BLUE,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)',
    transform: 'translate(0, 100%)',
    transition: 'transform 0.4s ease-in-out',
    minHeight: '250px',
    display: 'flex',
    justifyContent: 'center',
  },
  ({ visible }) =>
    visible && {
      transform: 'translate(0, 0)',
    }
);

const PopupContent = styled.div<{ hidden?: boolean }>(
  {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    padding: '32px',
    alignContent: 'space-between',
    flexFlow: 'column',
    opacity: 1,
    transition: EaseInOutTransition('opacity'),
    [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
      width: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  ({ hidden = false }) => {
    return hidden ? { display: 'none', opacity: 0 } : {};
  }
);

const PopupTextContainer = styled.div({
  flexGrow: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '16px',
  width: '100%',
});

const PopupText = styled.div({
  fontSize: '16px',
  lineHeight: '24px',
  color: COLORS.BLUE,
  textAlign: 'center',
});

const PopupProcessingText = styled.div({
  fontSize: '16px',
  lineHeight: '24px',
  color: COLORS.BLUE,
  paddingRight: '24px',
  [MEDIA_QUERY.TABLET]: {
    paddingRight: '0',
    paddingBottom: '24px',
  },
  [MEDIA_QUERY.MOBILE]: {
    paddingRight: '0',
    paddingBottom: '24px',
  },
});

const popupCircleKeyframes = keyframes`
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: scale(2) rotateY(0deg);
  }
  50% {
    transform: scale(2) rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: scale(2) rotateY(3600deg);
  }
`;

const LoadingContainer = styled.div({ margin: 'auto' });

const PopupProcessingCircleContainer = styled.div({
  width: '100%',
  textAlign: 'center',
  padding: '32px 0',
});

const PopupProcessingCircle = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${COLORS.BLUE};
  border-radius: 50%;
  animation: ${popupCircleKeyframes} 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

const PopupButtons = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  width: '100%',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    width: '100%',
  },
});

const ButtonCancel = styled.button(
  {
    height: '48px',
    width: '224px',
    boxSizing: 'border-box',
    margin: 0,
    border: `2px solid ${COLORS.BLUE}`,
    padding: '10px 0',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    textTransform: 'uppercase',
    background: 'transparent',
    color: COLORS.BLUE,
    outline: 'none',
    cursor: 'pointer',
    margin: '0 8px',
    transition:
      'background-color 0.4s ease, border-color 0.4s ease, color 0.4s ease',
    '&:hover': {
      backgroundColor: COLORS.LIGHT_DARK_GREY,
      borderColor: COLORS.LIGHT_DARK_GREY,
      color: COLORS.WHITE,
    },
    [MEDIA_QUERY.TABLET]: {
      width: '208px',
    },
    [MEDIA_QUERY.MOBILE]: {
      width: '47.619047619%',
    },
  },
  ({ disabled }) =>
    disabled && {
      opacity: 0.3,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: COLORS.BLUE,
        color: COLORS.BLUE,
      },
    }
);

const ButtonAccept = styled.button(
  {
    height: '48px',
    width: '224px',
    boxSizing: 'border-box',
    margin: 0,
    border: `2px solid ${COLORS.BLUE}`,
    padding: '10px 0',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    textTransform: 'uppercase',
    background: COLORS.BLUE,
    color: COLORS.WHITE,
    outline: 'none',
    cursor: 'pointer',
    margin: '0 8px',
    transition: 'background-color 0.4s ease, border-color 0.4s ease',
    '&:hover': {
      backgroundColor: COLORS.LIGHT_DARK_GREY,
      borderColor: COLORS.LIGHT_DARK_GREY,
    },
    [MEDIA_QUERY.TABLET]: {
      width: '208px',
    },
    [MEDIA_QUERY.MOBILE]: {
      width: '47.619047619%',
    },
  },
  ({ disabled }) =>
    disabled && {
      opacity: 0.3,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: COLORS.BLUE,
        borderColor: COLORS.BLUE,
      },
    }
);

const ThreeDSecureStarter = styled.div({ height: '500px' });

type Props = {
  __: (key: string) => string;
  visible: boolean;
  sending: boolean;
  onCancel: () => void;
  onAccept: () => void;
  additionalSecurityEnabled: boolean;
  errorMessage: null | string;
};

export const ConfirmPopup: React.FC<Props> = ({
  __,
  visible,
  sending,
  onCancel,
  onAccept,
  additionalSecurityEnabled,
  errorMessage,
}) => {
  const totalPrice = getTotalPrice();
  const yearlyPrice = totalPrice * 12;

  const ErrorMessage: React.FC = () => (
    <PopupContent>
      <PopupTextContainer>
        <PopupText>{errorMessage}</PopupText>
      </PopupTextContainer>
      <PopupButtons>
        <ButtonCancel type="button" onClick={onCancel}>
          {__('userDataForm.popup.close')}
        </ButtonCancel>
      </PopupButtons>
    </PopupContent>
  );

  const ActionButtons = () => (
    <PopupButtons>
      <ButtonCancel type="button" disabled={sending} onClick={onCancel}>
        {__('userDataForm.popup.cancel')}
      </ButtonCancel>
      <ButtonAccept type="button" disabled={sending} onClick={onAccept}>
        {__('userDataForm.popup.accept')}
      </ButtonAccept>
    </PopupButtons>
  );

  const TotalPriceInformation = () => (
    <PopupTextContainer>
      <PopupText>
        {__('userDataForm.popup.text')(formatPrice(yearlyPrice / 100))}
      </PopupText>
    </PopupTextContainer>
  );

  const Loading = () => (
    <PopupContent>
      <LoadingContainer>
        <PopupText>{__('userDataForm.popup.processing')}</PopupText>
        <PopupProcessingCircleContainer>
          <PopupProcessingCircle />
        </PopupProcessingCircleContainer>
      </LoadingContainer>
    </PopupContent>
  );

  const ThreeDSecure = () => (
    <PopupContent hidden={!additionalSecurityEnabled || errorMessage}>
      <PopupTextContainer>
        <PopupText>
          {additionalSecurityEnabled &&
            __('userDataForm.popup.additionalSecurity')}
        </PopupText>
      </PopupTextContainer>
      <ThreeDSecureStarter id="threedsecure" />
    </PopupContent>
  );

  const AcceptPrice = () => (
    <PopupContent>
      <TotalPriceInformation />
      <ActionButtons />
    </PopupContent>
  );

  const View = () => {
    if (errorMessage) {
      return <ErrorMessage />;
    } else if (additionalSecurityEnabled && sending) {
      return null;
    } else if (sending) {
      return <Loading />;
    } else {
      return <AcceptPrice />;
    }
  };

  return (
    <PopupContainer visible={visible}>
      <View />
      <ThreeDSecure />
    </PopupContainer>
  );
};
