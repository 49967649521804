/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../commonStyles';
import { formatPrice } from '../../../functions/utils';
import { get } from 'lodash';

const List = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

type Order = {
  title: string;
  price: number;
  basketImage: Image;
};

type File = {
  url: string;
};

type Image = {
  file: File;
};

const ProductContainer = styled.div({
  borderTop: '1px solid rgba(0,0,0,0.1)',
  width: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  fontSize: '18px',
  padding: '12px 0',
  alignItems: 'center',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '16px',
    ':first-child': {
      borderTop: 0,
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '6px 0',
    fontSize: '12px',
    ':first-child': {
      borderTop: 0,
    },
  },
});

const Thumbnail = styled.img({
  width: '96px',
  height: '96px',
  flex: '0 0 96px',
  objectFit: 'contain',
  [MEDIA_QUERY.TABLET]: {
    width: '48px',
    height: '48px',
    flex: '0 0 48px',
    marginLeft: '32px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '48px',
    height: '48px',
    flex: '0 0 48px',
    marginLeft: '15px',
  },
});

const ProductName = styled.div({
  padding: '0 8px',
  [MEDIA_QUERY.TABLET]: {
    padding: '0 20px 0 40px',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '0 10px 0 18px',
  },
});

const ProductPrice = styled.div({
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
  [MEDIA_QUERY.TABLET]: {
    marginRight: '56px',
  },
  [MEDIA_QUERY.MOBILE]: {
    marginRight: '30px',
  },
});

type OrdersProps = {
  // TODO: we need a consistent type for orders
  items: any[];
  i18n: (key: string) => string;
};

const OrdersList: React.FC<OrdersProps> = ({ items, i18n }) =>
  useMemo(() => {
    const Order: React.FC<Order> = ({ product, name, price }) => {
      const basketImage = get(product, '[0].basketImage');
      const image = basketImage ? `${basketImage.file.url}?w=288` : '';
      return (
        <ProductContainer>
          <Thumbnail src={image} />
          <ProductName>{name}</ProductName>
          <ProductPrice>{`${formatPrice(price / 100)}${i18n(
            'shoppingCart.eurPerMonth'
          )}`}</ProductPrice>
        </ProductContainer>
      );
    };
    return (
      <List>
        {items.map((p: Order, index) => (
          <Order key={index} {...p} />
        ))}
      </List>
    );
  }, []);

const OrdersContainer = styled.div({
  overflow: 'hidden',
  transition: 'max-height 0.3s linear',
  maxHeight: ({
    active,
    heightMultiplier,
  }: {
    active: boolean;
    heightMultiplier: number;
  }) => {
    return active ? `${heightMultiplier * 80}px` : '0';
  },
  [MEDIA_QUERY.DESKTOP]: {
    maxHeight: '100%',
  },
});

type Props = {
  items: any[];
  visible: boolean;
  i18n: (key: string) => string;
};

const Empty = styled.div({
  width: '100%',
  textAlign: 'center',
  textColor: 'black',
  fontSize: '18px',
  lineHeight: '30px',
  padding: '25px 0',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '16px',
    padding: '21px 0',
  },
});

export const Orders: React.FC<Props> = ({ visible, items, i18n }) => {
  const Component = useMemo(
    () =>
      items.length > 0
        ? () => <OrdersList i18n={i18n} items={items} />
        : () => <Empty>{i18n('shoppingCart.empty')} </Empty>,
    []
  );

  const heightMultiplier = items.length > 0 ? items.length : 1;

  return (
    <OrdersContainer heightMultiplier={heightMultiplier} active={visible}>
      <Component />
    </OrdersContainer>
  );
};
