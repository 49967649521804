/* eslint-disable @typescript-eslint/camelcase */
import { Form, Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../commonStyles';
import { getLink } from '../../../constants/links';
import { recurlyConfig } from '../../../data/config';
import { sendOrder } from '../../../functions/api';
import { redirectTo } from '../../../functions/redirect';
import { trackPurchasedProducts } from '../../../functions/utils/tracking';
import { getOrderedProducts } from '../../../storage/session-storage';
import { i18n } from '../../../translations';
import { AdditionalAddress } from './additional-address';
import { BasicInformation } from './basic-information';
import { ConfirmPopup } from './confirm-popup';
import { initialValues } from './data/initial-values';
import { validationSchema } from './data/schema';
import { Payment } from './payment';
import { Submit } from './submit';
import { getAge, getOrderPayload } from './utils';

type Props = {
  language: string;
};

const Container = styled.div({
  width: '480px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 auto',
    width: '512px',
    paddingTop: '86px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 15px',
    width: 'auto',
    paddingTop: '113px',
  },
});

const productVariantsQuery = (language: string) => {
  const { allContentfulProductVariant } = useStaticQuery(graphql`
    query ProductVariantsQuery {
      allContentfulProductVariant {
        nodes {
          node_locale
          name
          skuCode
          price
          net
          vat
          product {
            id
            title
            brand {
              title
            }
            category {
              name
            }
          }
        }
      }
    }
  `);
  return allContentfulProductVariant.nodes.filter(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

const UserForm: React.FC<Props> = ({ language }) => {
  const __ = i18n(language);
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRecurlyDataValid, setRecurlyDataValidity] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [formSending, setFormSending] = useState(false);
  // 3D Secure enabled
  // Initial token to kick off the 3D secure authentication
  const [initialToken, setInitialToken] = useState<string | null>(null);
  // Flag to prevent 3D Secure callback from unintentional token returns during frictionless flow
  // Additional protection to avoid double transactions in case if they are bugs in recurly.js
  let didReceiveToken = false;

  // Unknown 3D secure error
  const [popupError, showPopupError] = useState<null | string>(null);

  const orderedProducts = getOrderedProducts();
  const subscriptions = orderedProducts.map(({ skuCode }) => skuCode);
  const productVariants = productVariantsQuery('en-US');
  const orderedProductsFullInfo = productVariants.filter(
    pv => subscriptions.indexOf(pv.skuCode) >= 0
  );

  useEffect(() => {
    recurly.configure({
      publicKey: recurlyConfig.API_KEY,
    });

    recurly.on('change', ({ fields }) => {
      const isRecurlyInformationValid = Object.values(fields)
        .map(f => f.valid || false)
        .every(valid => valid);
      setRecurlyDataValidity(isRecurlyInformationValid);
    });
  }, []);

  useEffect(() => {
    if (initialToken) {
      const risk = recurly.Risk();
      const threeDSecure = risk.ThreeDSecure({
        actionTokenId: initialToken,
      });

      threeDSecure.on('token', function({ id: securityToken }) {
        if (!didReceiveToken) {
          didReceiveToken = true;
          setFormSending(true);
          handleSubmitSend({ ...formValues, securityToken });
        }
      });

      threeDSecure.on('error', function(e) {
        showPopupError(__('userDataForm.popup.securityFailure'));
      });

      threeDSecure.attach(document.querySelector('#threedsecure'));
      return () => {
        threeDSecure.remove();
      };
    }
  }, [initialToken]);

  const showErrorMessage = message => {
    setErrorMessage(message);
    setError(true);
    setShowPopup(false);
  };

  const handleSubmit = formValues => {
    const { birthYear, birthMonth, birthDay } = formValues;
    const age = getAge(birthYear, birthMonth, birthDay);
    // at least 18 years old
    if (age < 18) {
      showErrorMessage(`${__('userDataForm.errorAge')}`);
      return;
    }

    if (!isRecurlyDataValid) {
      showErrorMessage(`${__('userDataForm.errorCardDataMissing')}`);
      return;
    }

    setShowPopup(true);
    setFormValues(formValues);
    setFormSending(false);
  };

  const handleSubmitSend = async formValues => {
    try {
      const payload = await getOrderPayload(
        language,
        subscriptions,
        formValues
      );
      const {
        data: { success, purchaseId, requireAdditionalSteps, secureId },
      } = await sendOrder(payload);
      if (requireAdditionalSteps) {
        setInitialToken(secureId);
      } else if (success) {
        trackPurchasedProducts(purchaseId, orderedProductsFullInfo);
        redirectTo(getLink(language, 'thankYou'));
      } else {
        setInitialToken(null);
        throw new Error();
      }
    } catch (err) {
      const errorMessage = get(err, 'response.data.message');
      const error = errorMessage
        ? `${__(`userDataForm.${errorMessage}`)}`
        : `${__('userDataForm.errorException')}`;
      showPopupError(error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setFormValues(null);
    setFormSending(false);
  };

  const handlePopupAccept = async () => {
    setFormSending(true);
    await handleSubmitSend(formValues);
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitCount, errors, values }) => {
          const hasErrors = Object.keys(errors).length > 0;
          if (hasErrors || (!isRecurlyDataValid && submitCount > 0)) {
            showErrorMessage(__('userDataForm.errorFillFields'));
          }

          if (!hasErrors && isRecurlyDataValid) {
            setError(false);
          }

          const shouldShowError = showError || (hasErrors && submitCount > 0);
          return (
            <Form id="formik_form">
              <BasicInformation __={__} />
              <AdditionalAddress
                visible={values.hasAdditionalAddress}
                __={__}
              />
              <Payment __={__} />
              <Submit
                hasItems={subscriptions.length > 0}
                showError={shouldShowError}
                __={__}
                errorMessage={errorMessage}
                language={language}
              />
            </Form>
          );
        }}
      </Formik>
      <ConfirmPopup
        __={__}
        visible={showPopup}
        sending={formSending}
        onCancel={closePopup}
        onAccept={handlePopupAccept}
        additionalSecurityEnabled={initialToken !== null}
        errorMessage={popupError}
      />
    </Container>
  );
};

export default UserForm;
