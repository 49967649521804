export const getAge = (year: string, month: string, day: string): number => {
  const today = new Date();
  const birthDate = new Date(Number(year), Number(month), Number(day));
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getBirthDate = (
  birthYear: string,
  birthMonth: string,
  birthDay: string
): string => {
  const month = birthMonth.padStart(2, '0');
  const day = birthDay.padStart(2, '0');

  return `${month}-${day}-${birthYear}`;
};

const composeTokenPayload = formValues => {
  const {
    firstName,
    lastName,
    email,
    phonePrefix,
    phone,
    address,
  } = formValues;

  return {
    first_name: firstName,
    last_name: lastName,
    email,
    phone: `${phonePrefix} ${phone}`,
    address1: address.street[0],
    city: address.city,
    postal_code: address.zip,
    country: 'FI',
  };
};

const composeOrderPayload = (language, subscriptions, formValues, token) => {
  const {
    email,
    firstName,
    lastName,
    address,
    additionalAddress,
    phone,
    birthDay,
    birthMonth,
    birthYear,
    phonePrefix,
    acceptTerms,
    subscribeToNewsletter,
    hasAdditionalAddress,
    securityToken,
  } = formValues;

  const deliveryAddress = hasAdditionalAddress
    ? {
        'delivery-address': {
          firstName: additionalAddress.firstName,
          lastName: additionalAddress.lastName,
          phone: `${additionalAddress.phonePrefix} ${additionalAddress.phone}`,
          street: additionalAddress.street.filter(i => Boolean(i)),
          zip: additionalAddress.zip,
          city: additionalAddress.city,
        },
      }
    : {};

  const personalData = {
    email,
    address: {
      firstName,
      lastName,
      phone: `${phonePrefix} ${phone}`,
      street: address.street.filter(i => Boolean(i)),
      zip: address.zip,
      city: address.city,
    },
  };

  return {
    'personal-data': personalData,
    ...deliveryAddress,
    'tos-accepted': acceptTerms,
    'newsletter-accepted': subscribeToNewsletter,
    locale: language,
    language: language.substring(0, 2),
    birthday: getBirthDate(birthYear, birthMonth, birthDay),
    token,
    securityToken,
    subscriptions,
  };
};

const getRecurlyToken = tokenPayload => {
  return new Promise((resolve, reject) => {
    recurly.token(tokenPayload, (err, token) => {
      if (err) {
        reject(err);
      } else {
        resolve(token);
      }
    });
  });
};

export const getOrderPayload = async (language, subscriptions, formValues) => {
  const tokenPayload = composeTokenPayload(formValues);
  const token = await getRecurlyToken(tokenPayload);
  return composeOrderPayload(language, subscriptions, formValues, token.id);
};
